<template>
    <div class="points">
        <location :locationArr="locationArr"/>
        <title-tap v-if="typeList.length>0" :defaultActiveName="typeId" :activeTitle="typeList" @tabclick="tabclick"/>
        <!-- 商会简介 -->
        <div class="titleTap-box" v-if="typeId=='shjj'">
            <p class="titleTap-box-title" style="text-align: center">{{resData.title}}</p>
            <div class="titleTap-box-content" v-html="resData.shgkContent"></div>
        </div>
        <!-- 商会章程 -->
        <div class="titleTap-box" v-if="typeId=='shzc'">
            <p class="titleTap-box-title" style="text-align: center">{{resData.title}}</p>
            <div class="titleTap-box-content" v-html="resData.shgkContent"></div>
        </div>
        <!-- 表彰荣誉 -->
        <div class="titleTap-box" v-if="typeId=='bzry'">
            <div class="honor">
                <div class="honor-box" @click="goBzryDetail(item.id)" v-for="(item,index) in resData.records"
                     :key="index">
                    <div class="honor-box-top">
                        <img :src="item.titleImage">
                    </div>
                    <div class="honor-box-bottom">
                        <div class="fl">
                            <div class="qiu"></div>
                        </div>
                        <div class="boxBottom2">
                            {{item.title}}
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination style="text-align: right;margin: 60px 0;"
                           background
                           prev-text="上一页"
                           next-text="下一页"
                           layout="prev, pager, next"
                           :current-page="page"
                           @current-change="handleCurrentChange"
                           :page-size=3
                           :total="resData.total">
            </el-pagination>
        </div>
        <!-- 领导关怀-->
        <div class="titleTap-box" v-if="typeId=='ldgh'">
            <div class="care" @click="goLdghDetail(item.id)" v-for="(item,index) in resData.records" :key="index">
                <img :src="item.titleImage">
                <div class="care-content">
                    <h5>{{item.title}}</h5>
                    <p>{{item.subContent}}</p>
                </div>
                <div class="care-time">
                    <h3>{{item.year}}</h3>
                    <p>{{item.monthAndDay}}</p>
                </div>
            </div>
            <el-pagination style="text-align: right;margin: 60px 0;"
                           background
                           prev-text="上一页"
                           next-text="下一页"
                           layout="prev, pager, next"
                           :current-page="page"
                           @current-change="handleCurrentChange"
                           :page-size=4
                           :total="resData.total">
            </el-pagination>
        </div>
		<!-- 组织架构 -->
		<div class="titleTap-box" v-if="typeId == 'zzjg'">
			<div class="content-img">
				<img src="../../assets/img/zzjg2.png" >
			</div>
		</div>
		<!-- 会长致辞 -->
		<div class="titleTap-box hzzc" v-if="typeId == 'hzzc'">
			<div class="title">会长致辞</div>
			<div class="content-text">
				<p>中国侨商联合会自成立以来，在中国侨联的指导下，
				紧紧围绕党和国家发展大局，锐意进取，不断创新，
				为国家经济发展、“一带一路”建设、公益慈善事业等作了大量工作，
				在海内外有着广泛的知名度和美誉度。
				</p>
				<p>新中国自成立以来，创造了世所罕见的经济发展及社会长期稳定的大国奇迹，
				中国人民在中国共产党领导下自力更生、艰苦创业、锐意改革、不断前进，
				把一个“一穷二白”的旧中国，建设成为世界第二大经济体，实现从站起来、
				富起来到强起来的伟大飞跃。
				</p>
				<p>回顾新中国的发展史，我们无比自豪，中国是世界和平的建设者、
				全球发展的贡献者、国际秩序的维护者。展望未来，我们信心百倍，
				作为大变局中的稳定力量，新时代中国将为世界繁荣进步作出更大贡献。
				</p>
				<p>中国的发展，给广大侨商提供了更加广阔的舞台。
				中国侨商联合会的各位会员都有着赤诚的爱国情怀、雄厚的经济实力、
				丰富的智力资源、广泛的商业网络，是中国发展不可缺少的重要力量。
				站在新时代的起点，我们豪情满怀，壮志在胸。
				我们将更加紧密地团结在以习近平总书记为核心的党中央周围，
				更加广泛深入地参与到中华民族伟大复兴进程中来，
				为中国的创新发展和“一带一路”建设、为促进两岸和平统一和保持港澳繁荣稳定、
				为中国和世界的交流合作贡献更多的智慧和力量。
				</p>
				<p>中国侨商联合会作为服务侨商的全国性社会组织，将发挥好桥梁和纽带作用，集思广益，
				不断创新和进取。中国侨商联合会将努力成为服务会员和国家发展大局平台的“搭建者”，
				实施“一带一路”战略的“推广者”，举办各类活动的“组织者”，会员投资合法权益的“维护者”，
				传递信息、交流合作的“沟通者”。
				</p>
				<p>我们相信，有党中央的高度重视，有中国侨联的正确领导和指导，
				有广大侨商会员的精诚合作和积极参与，中国侨商联合会一定能够抓住机遇，
				蓬勃发展，成为温馨和谐的“侨商之家”。
				</p>
				<p>衷心感谢社会各界人士一直以来给予侨商群体的关心和帮助。
				乘风破浪，任重道远。让我们携手同心，汇聚起海内外中华儿女的力量，
				同心共圆中华民族伟大复兴的中国梦，同心构建人类命运共同体的美好未来！
				</p>
			</div>
			<div class="content-bottom">
				<div class="nameList">
					<p>中国侨商联合会会长</p>
					<img src="../../assets/img/list-01.png" >
				</div>
				<div class="nameList">
					<p>中国侨商联合会会长</p>
					<img src="../../assets/img/list-02.png" >
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    import location from '@/components/location.vue'
    import titleTap from '@/components/titleTap.vue'

    export default {
        name: 'shgkIndex',
        components: {
            location,
            titleTap
        },
        data() {
            return {
                typeId: 'shjj',
                typeList: [],
                resData: {},
                page: 1,
                locationArr: null
            }
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile/shgk",
						query:{
							typeId:this.$route.query.typeId
						}
			        });
			}
		},
        mounted() {
            this.refreshData();

            this.typeList = [
                {typeName: '商会简介', typeId: 'shjj', typeEnName: 'CHAMBER OF COMMERCE INTRODUCTION'},
				{typeName: '会长致辞', typeId: 'hzzc'},
				{typeName: '领导关怀', typeId: 'ldgh', typeEnName: 'LEADING CARE'},
				{typeName: '表彰荣誉', typeId: 'bzry', typeEnName: 'RECOGNITION OF HONOR'},
				{typeName: '组织架构', typeId: 'zzjg'},
                {typeName: '商会章程', typeId: 'shzc', typeEnName: 'CHAMBER OF COMMERCE CHARTER'},
			
            ];
        },
        methods: {
			// 点击tab栏切换，接收组件传递的typeid，再重新进入这个页面
            tabclick(item) {
                const typeId = item.typeId
                if (typeId != this.typeId) {
                    this.typeId = typeId
                    const url = this.$route.path;
                    this.$router.push({path: url, query: {typeId: typeId}})
                }
            },
			// 动态添加面包屑导航 获取typeid的值，给面包屑导航传值
            initLocation() {
                const locationArr = [{"title": "商会概况", "url": "/shgk"}];
                let title;
                let typeId = this.typeId;
                if (typeId == "shzc") {
                    title = "商会章程";
                } else if (typeId == "bzry") {
                    title = "表彰荣誉";
                } else if (typeId == "ldgh") {
                    title = "领导关怀";
                } else if(typeId == "zzjg"){
					title = '组织架构'
				} else if(typeId == "hzzc"){
					title = "会长致辞"
				}else {
                    title = "商会简介";
                    typeId = "shjj";
                }
                locationArr.push({"title": title, "url": "/shgk?typeId=" + typeId})
                // 将面包屑导航的数据赋值给面包屑的组件
				this.locationArr = locationArr;
            },
            // 表彰荣誉分页点击事件
            handleCurrentChange(val) {
				// 改变页数,获取数据
                this.page = val
                this.getData();
            },
            // 跳转至详情页
            goBzryDetail(id) {
                this.$router.push({name: 'shgkBzryDetail', query: {id: id}})
            },
            // 跳转至详情页
            goLdghDetail(id) {
                this.$router.push({name: 'shgkLdghDetail', query: {id: id}})
            },
			// 获取传递url传递过来的值 设置typeid的值 数据的默认值
			// 动态添加面包屑导航的值 和获取页面的数据
            refreshData() {
                let typeId = this.$route.query.typeId;
                if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
                    this.typeId = typeId
                } else {
                    this.typeId = 'shjj'
                }
                this.resData = {}
                this.page = 1;
                this.initLocation()
                this.getData();
            },
			//  根据typeid的值 动态获取数据
            getData() {
                switch (this.typeId) {
                    case 'shzc':
                        this.$http.shzcList().then(res => {
                            if (res.code == 200) {
                                this.resData = res.data
                            }
                        })
                        break;
                    case 'bzry':
                        this.$http.shbzryList({page: this.page}).then(res => {
                            if (res.code == 200) {
                                this.resData = res.data
                            }
                        })
                        break;
                    case 'ldgh':
                        this.$http.shldghList({page: this.page}).then(res => {
                            if (res.code == 200) {
                                this.resData = res.data
                            }
                        })
                        break;
                    default:
                        this.$http.shjjList().then(res => {
                            if (res.code == 200) {
                                this.resData = res.data
                            }
                        })
                        break;
                }
            }
        },
		// 监听事件  当路由发生变化就触发 refreshData方法
        watch: {
            $route: 'refreshData'
        }
    }
</script>
<style scoped>
    @import '~@/assets/css/points.css';
</style>
